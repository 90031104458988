import { Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { addData, deleteData, updateData } from '../../../api/tabsApi';
import useForm from '../../form/Form';
import { TColumnData } from '../../tabs/type';
import SubTabs from '../../tabs/SubTabs';
import { TState, useActions } from '../../../redux/store';
import { useCanI, useSubTabs } from '../../../redux/actions';

const useManageEntries = () => {
  const rowData = useSelector((state: TState) => state.rowData);
  const currentTab = useSelector((state: TState) => state.currentTab);
  const modalType = useSelector((state: TState) => state.modalType);
  const isAdmin = useSelector((state: TState) => state.isAdmin);

  const { setModalType, onClose, fetchAppointments, getData, setRowData } =
    useActions();

  const subTabs = useSubTabs();
  const canI = useCanI();

  const inputs = subTabs.reduce(
    (prev, tab) => [
      ...prev,
      ...tab.tableColumns.map((col) => ({
        ...col,
        parent_id: tab._id,
        type: col.type === 'text' ? '' : col.type,
      })),
    ],
    [] as TColumnData[],
  );

  const isAdd = modalType === 'addEntry';
  const isPlanning = currentTab?.isPlanning;

  const { Form, handleSubmit, pending } = useForm(
    inputs,
    isAdd ? addData : updateData,
    modalType !== 'addEntry' && currentTab?.planable,
  );

  const handleEntry = async () => {
    const resp = await handleSubmit();

    if (resp.isDuplicate) {
      return;
    }

    getData();

    if (!isAdd && isPlanning) {
      fetchAppointments();
      onClose();
    }

    setModalType('editEntry');
    setRowData(resp.data);
  };

  const handleDeleteEntry = async () => {
    await deleteData(rowData._id);
    getData();
    onClose();
  };

  const renderManageEntries = <SubTabs forms={Form} />;

  const updatePermission = currentTab?._id && canI(currentTab?._id, 'update');
  const deletePermission = isAdmin; //currentTab?._id && canI(currentTab?._id, 'delete');

  const renderDeleteEntry = (
    <Text>Confirm you want to delete this entry ?</Text>
  );

  return {
    addEntry: {
      component: renderManageEntries,
      action: handleEntry,
      title: 'Ajouter client',
      actionLabel: 'Ajouter',
    },
    editEntry: {
      component: renderManageEntries,
      canDelete: deletePermission ? 'deleteEntry' : '',
      action: updatePermission && handleEntry,
      pending,
      title: 'Editer client',
      actionLabel: 'Sauvegarder',
    },
    deleteEntry: {
      component: renderDeleteEntry,
      action: handleDeleteEntry,
      title: 'Supprimer client',
      actionLabel: 'Confirmer',
    },
  };
};

export default useManageEntries;
