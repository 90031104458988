import { Box } from '@chakra-ui/react';
import { useRef } from 'react';
import { useDrag, DragSourceMonitor } from 'react-dnd';

const DraggableItem = ({
  id,
  children,
  onDrag,
  onCancel,
  type,
}: {
  id: string;
  children?: any;
  onDrag?: any;
  onCancel?: any;
  type: string;
  noDrag?: boolean;
}) => {
  const preventDrag = useRef(false);
  const notDraged = useRef(true);

  const [, drag] = useDrag({
    type,
    item: { id },
    collect: (monitor: DragSourceMonitor) => {
      const isDragging = monitor.isDragging();

      if (isDragging && !preventDrag.current) {
        preventDrag.current = true;
        notDraged.current = false;
        onDrag?.();
      }

      if (!isDragging) {
        if (notDraged.current === false) {
          notDraged.current = true;
          onCancel?.();
        }
        preventDrag.current = false;
      }

      return {
        isDragging,
      };
    },
  });

  return (
    <Box ref={drag} cursor='move' w='100%'>
      {children}
    </Box>
  );
};

export default DraggableItem;
