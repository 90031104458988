import { useSelector } from 'react-redux';
import { Tooltip } from '@chakra-ui/react';
import { allIcons, CirlceIcon } from '../utils/Icon';
import { TState, useActions } from '../../redux/store';
import { useCanI } from '../../redux/actions';

const matchKey: Record<string, string> = {
  users: 'addUser',
  roles: 'addRole',
  inputs: 'addInput',
  documents: 'addDocument',
};

const ActionButtons = () => {
  const isEditMode = useSelector((state: TState) => state.isEditMode);
  const tabToUpdate = useSelector((state: TState) => state.tabToUpdate);
  const currentTab = useSelector((state: TState) => state.currentTab);

  const { setModalType } = useActions();
  const canI = useCanI();

  const customMode = matchKey[tabToUpdate?._id || ''];
  const modalType = customMode || (currentTab?._id && 'addEntry') || '';

  const canAdd = canI(currentTab?._id || '', 'create');
  const display =
    !isEditMode &&
    !currentTab?.isDuplicate &&
    !currentTab?.isPlanning &&
    modalType &&
    canAdd;

  return display ? (
    <Tooltip label='Ajouter'>
      <span>
        <CirlceIcon
          as={allIcons.HiDocumentPlus}
          mr={2}
          onClick={() => setModalType(modalType)}
        />
      </span>
    </Tooltip>
  ) : null;
};

export default ActionButtons;
