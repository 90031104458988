import { Flex, Box, Tooltip, Icon } from '@chakra-ui/react';
import { useGetSelectFromId } from '../../helpers';
import { TField } from './Form';
import { allIcons, CirlceIcon } from '../utils/Icon';
import { theme } from '../../theme';
import { useActions } from '../../redux/store';
import { useCanI } from '../../redux/actions';

type TFormGroupProps = {
  field: TField;
  input?: JSX.Element;
  prefixLabel?: string;
  planable?: boolean;
};

const FormGroup = ({
  field,
  input,
  prefixLabel,
  planable,
}: TFormGroupProps) => {
  const canI = useCanI();
  const { setModalType, setPlanningAddress } = useActions();
  const getSelectFromId = useGetSelectFromId();

  const isSwitch = /switch|checkbox/.test(field.type);
  const isTitle = /title/.test(field.type);
  const isAddress = /address/.test(field.type);

  return (
    <Flex
      minH={isSwitch ? '50px' : 'auto'}
      alignItems={isSwitch ? 'center' : 'flex-start'}
      w='100%'
      direction={isSwitch ? 'row' : 'column'}
      mt={isTitle ? 5 : 1}
      fontSize={field.type === 'title' ? '1.6em' : '1em'}
    >
      {(prefixLabel || field.label) && (
        <Flex
          textTransform='capitalize'
          m={0}
          mr={3}
          fontWeight='500'
          w={isSwitch ? 'auto' : '100%'}
          justifyContent={isTitle ? 'center' : 'left'}
          alignItems='center'
          lineHeight='32px'
          fontSize={isTitle ? theme.fontSize.bigger : theme.fontSize.small}
        >
          {prefixLabel ? `${prefixLabel} ` : ''}
          {isAddress && !!prefixLabel && field.label}
          {!isAddress &&
            (
              getSelectFromId(field.label || '')?.label || field.label
            ).toLowerCase()}
          {field.required && (
            <Icon
              as={allIcons.HiMiniStar}
              boxSize={2.5}
              ml={1}
              color='orange.400'
            />
          )}
          {planable && canI('planning', 'create') && (
            <Tooltip label='Fixer un rdv' placement='right'>
              <span>
                <CirlceIcon
                  mr={2}
                  as={allIcons.HiCalendarDays}
                  backgroundColor='none'
                  onClick={() => {
                    setPlanningAddress(field.name_id || '');
                    setModalType('addToPlanning');
                  }}
                />
              </span>
            </Tooltip>
          )}
        </Flex>
      )}
      {input ? (
        <Box w={isSwitch ? 'auto' : '100%'} textAlign='left'>
          {input}
        </Box>
      ) : null}
    </Flex>
  );
};

export default FormGroup;
