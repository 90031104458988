import { useCallback } from 'react';
import { useDrop } from 'react-dnd';
import DraggableItem from './DraggableItem';
import { Flex } from '@chakra-ui/react';

type TDropElementProps = {
  id: string;
  noDrag?: boolean;
  onDrop?: any;
  onCancel?: any;
  onDrag?: any;
  children?: any;
  type: string;
  onMove?: any;
};

const DropElement = ({
  id,
  noDrag,
  onDrop,
  onCancel,
  onDrag,
  children,
  type,
  onMove,
}: TDropElementProps) => {
  const onHover = useCallback(
    (item: any) => {
      if (item.index !== id) {
        onMove?.(item.id, id);
        item.index = id;
      }
    },
    [id],
  );

  const [, drop] = useDrop({
    accept: type,
    hover: onHover,
    drop: (item: any) => {
      onDrop?.(item.id, id);
    },
  });

  return (
    <Flex ref={drop} style={{ width: '100%' }}>
      {noDrag ? (
        children
      ) : (
        <DraggableItem id={id} onDrag={onDrag} onCancel={onCancel} type={type}>
          {children}
        </DraggableItem>
      )}
    </Flex>
  );
};

export default DropElement;
