import { isArray, uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import { Button, Flex, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import { deleteTabFilters, updateTabFilters } from '../../../api/tabsApi';
import { useGetSelectFromId, useGetUserFromId } from '../../../helpers';
import useForm from '../../form/Form';
import { TState, useActions } from '../../../redux/store';
import { useSubTabs } from '../../../redux/actions';

const useManageFilters = () => {
  const currentTab = useSelector((state: TState) => state.currentTab);
  const { getTabs } = useActions();
  const subTabs = useSubTabs();

  const getSelectFromId = useGetSelectFromId();
  const getUserFromId = useGetUserFromId();
  const { Form, formData, setFormData } = useForm(
    uniqBy(
      subTabs?.flatMap((tab) => {
        return tab.tableColumns
          ?.filter(
            (f) => /select/.test(f.type) || /created_by/.test(f.name_id || ''),
          )
          .map((f) => ({
            ...f,
            type: /select/.test(f.type) ? 'multiselect' : f.type,
          }));
      }),
      (f) => f._id,
    ),
  );

  const handleEditFilters = async (input: any, revert?: boolean) => {
    const filter = input.props.field.name_id;
    await updateTabFilters(currentTab?._id, {
      filter,
      values:
        !formData[filter] || isArray(formData[filter])
          ? formData[filter]
          : [formData[filter]],
      revert,
    });
    getTabs();
    setFormData({});
  };

  const handleDeleteFilters = async (f: any) => {
    await deleteTabFilters(currentTab?._id, f._id);
    getTabs();
    setFormData({});
  };

  const renderManageFilters = (
    <>
      {currentTab?.filters?.map((f: any) => (
        <Tag
          key={f._id}
          size='xl'
          borderRadius='full'
          variant='solid'
          colorScheme={f.revert ? 'red' : 'blue'}
          p={3}
          mr={3}
          mb={3}
        >
          <TagLabel textTransform='capitalize'>
            <i style={{ fontSize: '1.3em' }}>
              {getSelectFromId(f.filter)?.label || f.filter}
            </i>
            {`${f.revert ? ' différent de ' : ' égal à '}: ${
              getSelectFromId(f.filter)?.options?.[Number(f.value)] ||
              getUserFromId(f.value)?.name ||
              f.value ||
              'vide'
            }`}
          </TagLabel>
          <TagCloseButton onClick={() => handleDeleteFilters(f)} />
        </Tag>
      ))}
      {Form?.map((input: any) => {
        return (
          <Flex alignItems='self-end'>
            {input}
            <Button ml={3} onClick={() => handleEditFilters(input)}>
              Ajouter
            </Button>
            <Button ml={3} onClick={() => handleEditFilters(input, true)}>
              Retirer
            </Button>
          </Flex>
        );
      })}
    </>
  );

  return {
    editFilters: {
      component: renderManageFilters,
      title: 'Editer les filtres',
      actionLabel: 'Sauvegarder',
    },
  };
};

export default useManageFilters;
