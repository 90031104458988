import { useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  InputGroup,
  Table,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { CirlceIcon, allIcons } from '../utils/Icon';
import UploadComponent from '../import/UploadComponent';
import { TState } from '../../redux/store';

const Group = ({ d, onUpdate }: { d: any; onUpdate: any }) => {
  const filesComms = useSelector((state: TState) => state.filesComms);
  const rowData = useSelector((state: TState) => state.rowData);

  const ref = useRef<any>();

  return (
    <InputGroup w='100%'>
      <Textarea
        minH='50px'
        ref={ref}
        defaultValue={filesComms[d._id]}
        onBlur={() => {
          onUpdate({
            _id: rowData._id,
            [`${d._id}-comment`]: ref.current.value,
          });
        }}
      />
    </InputGroup>
  );
};

const DocumentTable = ({
  data,
  groupFiles,
  onView,
  onUpload,
  onUpdate,
}: any) => {
  const filesStatus = useSelector((state: TState) => state.filesStatus);
  const rowData = useSelector((state: TState) => state.rowData);

  return (
    <Table size='sm'>
      <Thead>
        <Tr>
          <Th w='200px'>Nom</Th>
          <Th>Commentaire</Th>
          <Th w='50px'>Complet</Th>
          <Th w='50px'>Voir</Th>
          <Th w='100px' textAlign='center'>
            Ajouter
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((d: any) => (
          <Tr>
            <Td whiteSpace='nowrap'>
              <Tooltip label={d.description} placement='bottom-start'>
                {d.name}
              </Tooltip>
            </Td>
            <Td>
              <Group d={d} onUpdate={onUpdate} />
            </Td>
            <Td>
              <CirlceIcon
                as={allIcons.HiCheck}
                backgroundColor={filesStatus[d._id] ? 'green.400' : ''}
                color={filesStatus[d._id] ? 'white' : ''}
                onClick={() => {
                  onUpdate({
                    _id: rowData._id,
                    [`${d._id}-isDone`]: !filesStatus[d._id],
                  });
                }}
              />
            </Td>
            <Td>
              {groupFiles[d._id]?.length ? (
                <Box position='relative' w='fit-content'>
                  <CirlceIcon
                    as={allIcons.HiEye}
                    mr={2}
                    onClick={() => onView(d._id)}
                  />
                  <Box position='absolute' top={0} right={0}>
                    {groupFiles[d._id]?.length}
                  </Box>
                </Box>
              ) : null}
            </Td>
            <Td>
              <UploadComponent
                multiple
                onUpload={(file) => onUpload(d._id, file)}
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default DocumentTable;
