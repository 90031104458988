import { useSelector } from 'react-redux';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Flex,
  Text,
  Switch,
  MenuGroup,
  Divider,
  Input,
  InputGroup,
  Heading,
  InputLeftElement,
  Box,
  Tooltip,
} from '@chakra-ui/react';
import { allIcons, CirlceIcon } from '../utils/Icon';
import ActionButtons from '../tabs/ActionButtons';
import UploadComponent from '../import/UploadComponent';
import { theme } from '../../theme';
import { TState, useActions } from '../../redux/store';
import { useCanI } from '../../redux/actions';

const Toolbar = () => {
  const tabs = useSelector((state: TState) => state.tabs);
  const currentTab = useSelector((state: TState) => state.currentTab);
  const tabToUpdate = useSelector((state: TState) => state.tabToUpdate);
  const globalFilter = useSelector((state: TState) => state.globalFilter);
  const login = useSelector((state: TState) => state.login);
  const isAdmin = useSelector((state: TState) => state.isAdmin);
  const isSuper = useSelector((state: TState) => state.isSuper);
  const isEditMode = useSelector((state: TState) => state.isEditMode);
  const filterActive = useSelector((state: TState) => state.filterActive);
  const darkLight = useSelector((state: TState) => state.darkLight);
  const isMobile = useSelector((state: TState) => state.isMobile);
  const {
    setCurrentTab,
    setGlobalFilter,
    setUser,
    setModalType,
    setToken,
    toggleFilter,
    onToggle,
    toggleEditMode,
    onNavOpen,
  } = useActions();

  const canI = useCanI();

  const handleMode = () => {
    if (isEditMode) {
      onToggle();
    } else {
      onNavOpen();
    }

    setTimeout(() => {
      toggleEditMode();
    }, 325);

    if (currentTab?.isSetting) {
      setCurrentTab(tabs[0]);
    }
  };

  return (
    <Flex
      pb={theme.margin}
      alignItems='center'
      flexWrap='wrap'
      zIndex={3}
      gap={3}
    >
      <Heading
        flexGrow={1}
        textTransform='capitalize'
        fontWeight='bold'
        fontSize={theme.fontSize.header}
        color={theme.main[darkLight]}
        whiteSpace='nowrap'
        textOverflow='ellipsis'
        overflow='hidden'
      >
        {currentTab?.title}
      </Heading>
      <Box flexGrow={1} />
      <Flex
        className='container'
        borderRadius='15px'
        bgColor={theme.navbarBg[darkLight]}
        alignItems='center'
        w={['100%', '100%', 'min-content']}
        m={0}
        py={1}
      >
        <CirlceIcon
          as={allIcons.HiBars3}
          color={theme.secondary[darkLight]}
          onClick={onToggle}
          cursor='pointer'
          ml={3}
          display={['block', 'none']}
        />
        <InputGroup w={['100%', '100%', 'min-content']} ml={3}>
          <InputLeftElement
            children={
              <Icon
                as={allIcons.HiMagnifyingGlass}
                color={theme.secondary[darkLight]}
                cursor='pointer'
              />
            }
          />
          <Input
            variant='search'
            bg={theme.background[darkLight]}
            color={theme.main[darkLight]}
            _placeholder={{
              color: theme.secondary[darkLight],
            }}
            borderRadius='30px'
            placeholder='Rechercher'
            onChange={(e) => setGlobalFilter(e.target?.value || undefined)}
            value={globalFilter}
            transition='all 0.3s ease'
            w={['100%', '100%', '200px']}
            p={'auto'}
            mr={2}
          />
        </InputGroup>
        <Tooltip label='Filtres'>
          <span>
            <CirlceIcon
              as={allIcons[filterActive ? 'HiFunnel' : 'HiOutlineFunnel']}
              onClick={() => toggleFilter()}
              mr={2}
            />
          </span>
        </Tooltip>
        {!isEditMode &&
          !currentTab?.isPlanning &&
          tabToUpdate?._id &&
          canI(tabToUpdate?._id, 'update') && (
            <Tooltip label='Actions multiples'>
              <span>
                <CirlceIcon
                  as={allIcons.HiMiniWallet}
                  onClick={() => setModalType('runAction')}
                  mr={2}
                />
              </span>
            </Tooltip>
          )}
        <Tooltip label='Importer un fichier excel'>
          <span>{!isMobile && isAdmin ? <UploadComponent /> : null}</span>
        </Tooltip>
        {<ActionButtons />}
        {/* <Icon
          color={theme.secondary[darkLight]}
          as={allIcons[darkLight ? 'HiMiniMoon' : 'HiSun']}
          mx={2}
          onClick={() => setDarkLight((dl) => (dl ? 0 : 1))}
        /> */}
        <Menu>
          <MenuButton mr={2}>
            <Icon
              display='block'
              as={allIcons.HiUserCircle}
              boxSize='40px'
              color={theme.dark[darkLight]}
            />
          </MenuButton>
          <MenuList>
            <MenuGroup
              title={`Connecté: ${login}`}
              fontSize={theme.fontSize.normal}
            />
            {/* <MenuGroup title='Options'> */}
            {/* <MenuItem>
                <Text flexGrow={1} onClick={toggleFilter}>
                  Avec filtres
                </Text>
                <Switch isChecked={filterActive} onChange={toggleFilter} />
              </MenuItem> */}
            {/* <MenuItem>
                <Text flexGrow={1} onClick={() => setShowNbLine((b) => !b)}>
                  Avec numero de ligne
                </Text>
                <Switch
                  isChecked={showNbLine}
                  onChange={() => setShowNbLine((b) => !b)}
                />
              </MenuItem> */}
            {isAdmin && !isSuper && (
              <MenuItem>
                <Text flexGrow={1} onClick={handleMode}>
                  Mode édition
                </Text>
                <Switch isChecked={isEditMode} onChange={handleMode} />
              </MenuItem>
            )}
            {/* </MenuGroup> */}
            <Divider my={3} />
            <MenuItem
              onClick={() =>
                setUser((u: any) => ({ ...u, tempPasswordForced: true }))
              }
            >
              Changer mon mot de passe
            </MenuItem>
            <Divider my={3} />
            <MenuItem onClick={() => setToken('')}>Déconnexion</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default Toolbar;
