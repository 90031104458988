import { useEffect, useState } from 'react';
import { cloneDeep, orderBy } from 'lodash';
import { Box, Flex, Input, Tooltip } from '@chakra-ui/react';

import ColorPicker, { allColors } from './ColorPicker';
import { CirlceIcon, allIcons } from '../utils/Icon';
import { getRandom, indexOfObject } from '../../helpers';
import DragDropList from '../dragAndDrop/DragDropList';

export type TSelectInputData = {
  value: string;
  color: string;
  order: number;
  disabled: boolean;
  id: number;
};

type TSelectInput = {
  setFormData: any;
  orderedOptions?: TSelectInputData[];
};

const emptyValueAtIndex = (v: any[], idx: number) => {
  const newArray = cloneDeep(v);
  const index = indexOfObject(v, 'order', idx);
  newArray[index].disabled = !newArray[index].disabled;
  return newArray;
};

const Item = ({
  item: { value, color, disabled },
  idx,
  setItems,
}: {
  item: TSelectInputData;
  idx: number;
  setItems: (v: any) => any;
}) => {
  return (
    <Flex alignItems='center' key={idx}>
      <Input
        isDisabled={disabled}
        key={`${idx}-${value}`}
        type='text'
        defaultValue={value || ''}
        mt={1}
        mr={2}
        onBlur={(e) =>
          setItems((v: TSelectInputData[]) => {
            const newV = cloneDeep(v);
            const index = indexOfObject(newV, 'order', idx);
            newV[index].value = e.target.value;
            return newV;
          })
        }
        flex={1}
      />
      <ColorPicker
        bg={color || 'transparent'}
        onChange={(e) =>
          setItems((c: TSelectInputData[]) => {
            const newColors = cloneDeep(c || []);
            const index = indexOfObject(newColors, 'order', idx);
            newColors[index].color = e.hex;
            return newColors;
          })
        }
      />
      <Tooltip label={disabled ? 'Activer' : 'Désactiver'}>
        <span>
          <CirlceIcon
            ml={3}
            as={disabled ? allIcons.HiCheck : allIcons.HiTrash}
            onClick={() => {
              setItems((v: any) => emptyValueAtIndex(v, idx));
            }}
          />
        </span>
      </Tooltip>
    </Flex>
  );
};

const SelectInput = ({ orderedOptions = [], setFormData }: TSelectInput) => {
  const [items, setItems] = useState<TSelectInputData[]>(orderedOptions);

  useEffect(() => {
    setFormData(items);
  }, [items]);

  return (
    <Box position='relative'>
      <DragDropList
        listItem={orderBy(items, 'order')}
        List={({ item, idx }) => (
          <Item item={item} idx={idx} setItems={setItems} />
        )}
        type={'SELECT_LIST'}
        onDrop={(newOrder) => {
          const newItems = items.map((opt, idx) => ({
            ...opt,
            order: indexOfObject(newOrder, 'id', idx),
          }));

          setItems(newItems);
        }}
      />
      <CirlceIcon
        my={3}
        as={allIcons.HiPlusSmall}
        onClick={() => {
          setItems((v) => [
            ...(v || []),
            {
              value: '',
              color: getRandom(allColors),
              order: items.length,
              id: items.length,
              disabled: false,
            },
          ]);
        }}
      />
    </Box>
  );
};

export default SelectInput;
