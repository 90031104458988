import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Icon,
  Tag,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { TColumnData, TTab } from '../tabs/type';
import { CirlceIcon, allIcons } from '../utils/Icon';
import { theme } from '../../theme';
import { updateTabOrder } from '../../api/tabsApi';
import { TState, useActions } from '../../redux/store';
import DragDropList from '../dragAndDrop/DragDropList';

type TMenuProps = {
  tab: Partial<TTab>;
  isSelected: boolean;
  isHovered: boolean;
  onClick?: any;
  isOpen?: boolean;
  isCustom?: boolean;
};

const MenuItem = ({
  tab: { _id: tabId, title, icon, isMenu, menuChilds, cloneId },
  isSelected,
  onClick,
  isHovered,
  isOpen,
  isCustom,
}: TMenuProps) => {
  const darkLight = useSelector((state: TState) => state.darkLight);
  const isEditMode = useSelector((state: TState) => state.isEditMode);
  const dataCount = useSelector((state: TState) => state.dataCount);
  const { setModalType } = useActions();

  const [isTruncated, setIsTrucated] = useState(false);
  const ref = useRef<any>();

  const hasCount = dataCount[`${tabId}`] > 0;

  useEffect(() => {
    setTimeout(() => {
      setIsTrucated(
        ref.current && ref.current?.scrollWidth > ref.current?.clientWidth,
      );
    }, 301);
  }, [isHovered, ref.current?.clientWidth]);

  return (
    <Flex
      position='relative'
      w='100%'
      px={4}
      py={!isHovered ? 3 : isMenu ? 0 : 1}
      pl={isHovered && isMenu ? 8 : 3}
      margin={isHovered ? 'none' : 'auto'}
      cursor='pointer'
      alignItems='center'
      color={theme[isSelected ? 'none' : 'main'][darkLight]}
      bgColor={theme[isSelected ? 'normal' : 'none'][darkLight]}
      fontWeight={isSelected ? '500' : '0'}
      onClick={() => {
        onClick();
      }}
    >
      <Icon
        as={allIcons[icon || 'HiFolder']}
        boxSize={theme.fontSize[isHovered ? 'big' : 'header']}
        color={isSelected ? theme.none[darkLight] : theme.dark[darkLight]}
      />
      {isHovered && (
        <Box
          ref={ref}
          p={1}
          px={2}
          fontSize={isMenu ? theme.fontSize.small : theme.fontSize.normal}
          textAlign='center'
          textTransform='capitalize'
          whiteSpace='nowrap'
          textOverflow='ellipsis'
          overflow='hidden'
        >
          {title}
        </Box>
      )}
      {hasCount && (
        <Box
          px={1}
          py={0.3}
          bgColor={theme.lighter[darkLight]}
          color={theme.darker[darkLight]}
          borderRadius='100px'
          fontSize={isHovered ? theme.fontSize.xSmall : '8.5px'}
          fontWeight={600}
          style={
            isHovered
              ? {}
              : {
                  position: 'absolute',
                  top: 3,
                  right: 3,
                }
          }
        >{`${dataCount[`${tabId}`]}`}</Box>
      )}
      {(!isHovered || isTruncated) && (
        <Tooltip label={title} placement='right' textTransform='capitalize'>
          <Box position='absolute' w='100%' h='100%' top={0} left={0} />
        </Tooltip>
      )}
      <Box flexGrow={1} />
      {!isEditMode && !!menuChilds?.length && isHovered && (
        <Icon
          color={isSelected ? theme.none[darkLight] : theme.dark[darkLight]}
          as={isOpen ? allIcons.HiChevronUp : allIcons.HiChevronDown}
          boxSize={15}
        />
      )}
      {!isCustom && isEditMode && (
        <Flex ml={3} zIndex={1}>
          {cloneId ? (
            <Tag mr={1}>Clone</Tag>
          ) : (
            <CirlceIcon
              as={allIcons.HiMiniDocumentDuplicate}
              onClick={() => {
                setModalType('duplicateTab');
              }}
            />
          )}
          {!isMenu && !cloneId && (
            <CirlceIcon
              as={allIcons.HiPlusSmall}
              onClick={() => {
                setModalType('addMenuSubTab');
              }}
            />
          )}
          <CirlceIcon
            as={allIcons.HiPencilSquare}
            onClick={() => {
              setModalType(isMenu ? 'editMenuSubTab' : 'editTab');
            }}
          />
          <CirlceIcon
            as={allIcons.HiMiniXMark}
            onClick={() => {
              setModalType('delTab');
            }}
          />
        </Flex>
      )}
    </Flex>
  );
};

const Elements = ({ tab, hideChilds }: { tab: TTab; hideChilds?: boolean }) => {
  const isEditMode = useSelector((state: TState) => state.isEditMode);
  const isHovered = useSelector((state: TState) => state.isHovered);
  const darkLight = useSelector((state: TState) => state.darkLight);
  const currentTab = useSelector((state: TState) => state.currentTab);

  const { setCurrentTab, setTabToUpdate, getTabs } = useActions();

  const [index, setIndex] = useState(-1);

  const keepOpen =
    tab._id === currentTab?._id ||
    !!tab.menuChilds?.find((mc) => mc._id === currentTab?._id);

  const handleChange = (tab: TTab) => {
    setCurrentTab(tab);
    setTabToUpdate(tab);
  };

  const onClick = useCallback(() => handleChange(tab), []);

  const handleOrderChange = useCallback(async (tabsOrdered: TTab[]) => {
    await updateTabOrder(tabsOrdered.map((t) => t._id));
    getTabs();
  }, []);

  if (!isEditMode) {
    return tab.menuChilds?.length ? (
      <Box position='relative'>
        <Box
          p={0}
          onMouseEnter={() => setIndex(0)}
          onMouseLeave={() => setIndex(-1)}
        >
          <MenuItem
            key={tab._id}
            tab={tab}
            isSelected={tab._id === currentTab?._id}
            onClick={() => handleChange(tab)}
            isHovered={isHovered}
            isOpen={keepOpen || index >= 0}
          />
        </Box>
        <Collapse in={keepOpen || index >= 0} animateOpacity>
          <Box
            p={0}
            onMouseEnter={() => setIndex(0)}
            onMouseLeave={() => setIndex(-1)}
          >
            {tab.menuChilds?.map((menuChild) => (
              <MenuItem
                key={menuChild._id}
                tab={menuChild}
                isSelected={menuChild._id === currentTab?._id}
                onClick={() => handleChange(menuChild)}
                isHovered={isHovered}
              />
            ))}
          </Box>
        </Collapse>
        {!isHovered && (
          <Box
            w='2px'
            h='98%'
            top='1%'
            right={0}
            bgColor={
              !keepOpen || (!keepOpen && index >= 0)
                ? theme.secondary[darkLight]
                : theme.light[darkLight]
            }
            opacity={!keepOpen || (!keepOpen && index >= 0) ? 0.5 : 1}
            position='absolute'
            borderRadius='40px'
          />
        )}
      </Box>
    ) : (
      <Box>
        <MenuItem
          key={tab._id}
          tab={tab}
          isSelected={tab._id === currentTab?._id}
          onClick={() => handleChange(tab)}
          isHovered={isHovered}
        />
      </Box>
    );
  }

  return (
    <Flex flexDirection='column' w='100%' py={1}>
      <MenuItem
        key={tab._id}
        tab={tab}
        isSelected={tab._id === currentTab?._id}
        onClick={onClick}
        isHovered={isHovered}
      />
      {hideChilds ? null : isEditMode ? (
        <DragDropList
          onDrop={handleOrderChange}
          listItem={tab.menuChilds || []}
          List={({ item }) => <Elements tab={item} />}
          type='SUBMENU'
        />
      ) : (
        tab.menuChilds?.map((menuChild) => <Elements tab={menuChild} />)
      )}
    </Flex>
  );
};

const Navbar = () => {
  const isEditMode = useSelector((state: TState) => state.isEditMode);
  const isHovered = useSelector((state: TState) => state.isHovered);
  const isPlanning = useSelector((state: TState) => state.isPlanning);
  const isDuplicate = useSelector((state: TState) => state.isDuplicate);
  const darkLight = useSelector((state: TState) => state.darkLight);
  const tabs = useSelector((state: TState) => state.tabs);
  const settingsTabs = useSelector((state: TState) => state.settingsTabs);
  const duplicateTab = useSelector((state: TState) => state.duplicateTab);
  const planningTab = useSelector((state: TState) => state.planningTab);
  const currentTab = useSelector((state: TState) => state.currentTab);

  const { onToggle, setModalType, setCurrentTab, getTabs } = useActions();

  const handleChange = async (tab: TTab) => {
    setCurrentTab(tab);
  };

  const handleOrderChange = async (tabsOrdered: TTab[]) => {
    await updateTabOrder(tabsOrdered.map((t) => t._id));
    getTabs();
  };

  const { isOpen: canDrag, onToggle: toggleCanDrag } = useDisclosure();

  return (
    <Flex
      id='navbar'
      className='container'
      direction='column'
      position={['absolute', 'relative']}
      zIndex={4}
      w={isHovered ? ['100%', isEditMode ? '400px' : '320px'] : '48px'}
      h={isHovered ? ['100%', 'auto'] : 'auto'}
      visibility={isHovered ? 'visible' : ['hidden', 'visible']}
      opacity={isHovered ? '1' : ['0', '1']}
      bg={theme.none[darkLight]}
      transition={['opacity 0.3s ease, width 0.3s ease', 'all 0.3s ease']}
    >
      <Box
        margin='auto'
        overflow='hidden'
        boxSize={10}
        flexShrink={0}
        mt={isHovered ? 3 : '5px'}
        mb={!isHovered ? 5 : 3}
      >
        <img src='/logoEVision.png' />
      </Box>
      {isEditMode && (
        <Button onClick={toggleCanDrag}>Afficher/Cacher les sous-menu</Button>
      )}
      <Box overflow='auto' flexGrow={1} mt={isEditMode ? 3 : 0}>
        {isEditMode && canDrag ? (
          <DragDropList
            onDrop={handleOrderChange}
            List={({ item }) => <Elements tab={item} hideChilds={true} />}
            listItem={tabs}
            type='MENU'
          />
        ) : (
          tabs.map((tab) => <Elements tab={tab} />)
        )}
        {isEditMode && (
          <CirlceIcon
            as={allIcons.HiPlusSmall}
            m={3}
            onClick={() => {
              setModalType('addTab');
            }}
          />
        )}
      </Box>
      <Divider />
      <Box fontSize={theme.fontSize.normal}>
        {isPlanning && (
          <MenuItem
            tab={{
              title: 'planning',
              icon: 'HiCalendarDays',
            }}
            isSelected={!!currentTab?.isPlanning}
            onClick={() => {
              handleChange(planningTab);
            }}
            isHovered={isHovered}
            isCustom
          />
        )}
        {isDuplicate && (
          <MenuItem
            tab={{
              title: 'doublons',
              icon: 'HiDocumentDuplicate',
            }}
            isSelected={!!currentTab?.isDuplicate}
            onClick={() => {
              handleChange(duplicateTab);
            }}
            isHovered={isHovered}
            isCustom
          />
        )}
        {!isEditMode && !!settingsTabs.length && (
          <MenuItem
            tab={{
              title: 'paramètres',
              icon: 'HiCog6Tooth',
            }}
            isSelected={!!currentTab?.isSetting}
            onClick={() =>
              handleChange({
                _id: 'settings',
                title: 'Paramètres',
                isSetting: true,
                tableColumns: [] as TColumnData[],
              } as TTab)
            }
            isHovered={isHovered}
          />
        )}
        {!isEditMode && (
          <CirlceIcon
            as={allIcons[`HiArrow${isHovered ? 'Left' : 'Right'}Circle`]}
            onClick={() => onToggle()}
            margin='auto'
            my={3}
          />
        )}
      </Box>
    </Flex>
  );
};

export default Navbar;
