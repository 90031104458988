import { entries, values } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  bindActionCreators,
  configureStore,
  createSlice,
} from '@reduxjs/toolkit';
import {
  TTab,
  TSettingsTab,
  TInput,
  TTabToUpdate,
} from '../components/tabs/type';
import {
  fetchAppointments,
  getData,
  getDataCount,
  getDataDetail,
  getDocumentList,
  getInputs,
  getRolesList,
  getTabs,
  getUserRoleContext,
  getUsersList,
} from './asyncActions';
import moment from 'moment';

const localToken = localStorage.getItem('token') || '';

const MAX_MOBILE_SIZE = 480;
const MAX_TABLET_SIZE = 1200;

export const initialState = {
  modalType: '',
  modalData: undefined as any,
  token: localToken,
  isEditMode: false,
  userRole: {} as Record<string, string[]>,
  isAdmin: false,
  isSuper: false,
  login: '',
  isHovered: false,
  showNbLine: true,
  isPlanning: false,
  isDuplicate: false,
  planningAddress: '',
  isPlanningEdit: false,
  appointments: {} as any,
  user: {} as Record<string, any>,
  darkLight: 0,
  isMobile: window.innerWidth < MAX_MOBILE_SIZE,
  isTablet:
    window.innerWidth >= MAX_MOBILE_SIZE && window.innerWidth < MAX_TABLET_SIZE,
  calendarCurrentDay: moment().toISOString(),

  // custom action
  isOpen: false,
  filterActive: false,

  tabs: [] as TTab[],
  tabTitles: {} as Record<string, string>,
  settingsTabs: [] as TSettingsTab[],
  planningTab: {} as TTab,
  duplicateTab: {} as TTab,
  userList: [] as any[],
  roleList: [] as any[],
  dataInputs: [] as TInput[],
  documentList: [] as any[],
  data: [] as any[],
  noFilter: false as boolean,
  dataCount: {} as Record<string, number>,
  currentTab: undefined as TTab | undefined,
  tabToUpdate: undefined as TTabToUpdate | undefined,
  currentColumnId: '',
  rowData: {} as Record<string, any>,
  filesStatus: {} as Record<string, boolean>,
  filesComms: {} as Record<string, string>,
  appointsHistory: {} as Record<string, any>,
  rowHistory: {} as Record<string, any>,
  rowSelected: [] as string[],
  globalFilter: '' as string,
  columnFilters: [] as Record<string, any>[],

  pendings: {} as Record<string, number>,
};

const stateNames = [
  'setModalType',
  'setModalData',
  'setToken',
  'setIsEditMode',
  'setUserRole',
  'setIsAdmin',
  'setIsSuper',
  'setUser',
  'setLogin',
  'setShowNbLine',
  'setIsPlanning',
  'setIsDuplicate',
  'setPlanningAddress',
  'setIsPlanningEdit',
  'setAppointments',
  'setDarkLight',
  'setIsMobile',
  'setIsTablet',
  'setCalendarCurrentDay',

  'setTabs',
  'setTabTitles',
  'setSettingsTabs',
  'setPlanningTab',
  'setDuplicateTab',
  'setUserList',
  'setRoleList',
  'setDataInputs',
  'setDocumentList',
  'setData',
  'setNoFilter',
  'setDataCount',
  'setCurrentTab',
  'setTabToUpdate',
  'setCurrentColumnId',
  'setRowData',
  'setAppointsHistory',
  'setRowHistory',
  'setRowSelected',
  'setGlobalFilter',
  'setColumnFilters',
  'setFilesStatus',
  'setFilesComms',
] as const;

const customActions = [
  'onToggle',
  'onClose',
  'onOpen',
  'toggleFilter',
  'onNavOpen',
  'toggleEditMode',
] as const;

export type TState = typeof initialState;
export type TActions = Record<
  (typeof stateNames)[number] | (typeof customActions)[number],
  any
>;

const actionsList = stateNames.reduce(
  (prev, curr) => ({
    ...prev,
    [curr]: (state: any, { payload }: any) => {
      const key: any = curr.replace(/^set/gi, '');
      const [first, ...word] = key;
      const newKey = `${first.toLowerCase()}${word.join('')}` as keyof TState;

      if (typeof payload === 'function') {
        state[newKey] = payload(state[newKey]);
      } else {
        state[newKey] = payload;
      }
    },
  }),
  {} as Record<(typeof stateNames)[number], any>,
);

const extras = {
  fetchAppointments,
  getTabs,
  getInputs,
  getDocumentList,
  getDataCount,
  getData,
  getDataDetail,
  getUserRoleContext,
  getRolesList,
  getUsersList,
};

const globalSlice = createSlice({
  name: 'global',
  initialState: initialState,
  reducers: {
    ...actionsList,
    setToken: (state: TState, { payload: t }: any) => {
      if (!t) {
        localStorage.removeItem('currentTab');
      }

      localStorage.setItem('token', t);
      state.token = t;
    },
    onClose: (state: TState) => {
      state.isOpen = false;
    },
    onOpen: (state: TState) => {
      state.isOpen = true;
    },
    toggleFilter: (state: TState) => {
      state.filterActive = !state.filterActive;
    },
    onNavOpen: (state: TState) => {
      state.isHovered = true;
    },
    onToggle: (state: TState) => {
      state.isHovered = !state.isHovered;
    },
    toggleEditMode: (state: TState) => {
      state.isEditMode = !state.isEditMode;
    },
  },
  extraReducers: (builder: any) => {
    values(extras).forEach((v) => {
      builder.addCase(v.pending, (state: TState, action: any) => {
        state.pendings[action.type] = state.pendings[action.type]
          ? state.pendings[action.type] + 1
          : 1;
      });
      builder.addCase(v.fulfilled, (state: TState, action: any) => {
        entries(action.payload).forEach(([k, v]) => ((state as any)[k] = v));
        state.pendings[action.type.replace('/fulfilled', '/pending')] = 0;
      });
      builder.addCase(v.rejected, (state: TState, action: any) => {
        state.pendings[action.type.replace('/rejected', '/pending')] = 0;
      });
    });
  },
});

export const actions: TActions = globalSlice.actions;

export const useActions = () => {
  const dispatch = useDispatch();

  const asyncActions = entries(extras).reduce(
    (prev, [k, v]) => ({
      ...prev,
      [k]: (props: any) => dispatch(v(props) as any),
    }),
    {} as Record<keyof typeof extras, any>,
  );

  return { ...bindActionCreators(actions, dispatch), ...asyncActions };
};

const store = configureStore({
  reducer: globalSlice.reducer,
});

export default store;
