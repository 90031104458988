import { IAppointment, NearestAppointment } from '../components/calendar/types';
import { axiosInstance } from './init';

export const getNearestAppointments = async (params: {
  city: string;
  dept: string;
  postal: string;
  street: string;
  date: any;
}) => {
  const response = await axiosInstance.get<{ data: NearestAppointment[] }>(
    '/api/appointments/nearest',
    {
      params,
    },
  );

  return response.data;
};

export const getDistance = async (params: {
  origin: string;
  destination: string;
}) => {
  const response = await axiosInstance.get('/api/appointments/distance', {
    params,
  });

  return response.data;
};

export const addAppointment = async (data: any) => {
  const response = await axiosInstance.post<{
    data: Record<
      string,
      Record<string, Record<string, Record<string, IAppointment[]>>>
    >;
  }>('/api/appointments', data);
  return response.data;
};

export const updateAppointment = async (id: string, data: any) => {
  const response = await axiosInstance.put<{
    data: Record<
      string,
      Record<string, Record<string, Record<string, IAppointment[]>>>
    >;
  }>(`/api/appointments/${id}`, data);
  return response.data;
};

export const getAppointments = async (currentDay: any) => {
  const response = await axiosInstance.get<{ data: IAppointment[] }>(
    '/api/appointments',
    { params: { currentDay } },
  );
  return response.data;
};

export const deleteAppointment = async (id: string) => {
  const response = await axiosInstance.delete(`/api/appointments/${id}`);
  return response.data;
};
