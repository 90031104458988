import { useEffect, useState } from 'react';
import { entries } from 'lodash';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  addTab,
  deleteTab,
  duplicateTab,
  updateTab,
} from '../../../api/tabsApi';
import { allIcons, pageIcons } from '../../utils/Icon';
import { TState, useActions } from '../../../redux/store';

const useManageTabs = () => {
  const planningTab = useSelector((state: TState) => state.planningTab);
  const currentTab = useSelector((state: TState) => state.currentTab);
  const tabToUpdate = useSelector((state: TState) => state.tabToUpdate);
  const modalType = useSelector((state: TState) => state.modalType);

  const { onClose, getTabs } = useActions();

  const [newTabTitles, setNewTabTitles] = useState(['']);
  const [svgIcon, setSvgIcon] = useState<string>('');
  const [planable, setPlanable] = useState(false);
  const [isClone, setIsClone] = useState(false);
  const [count, setCount] = useState(1);

  const isAdd = /add/.test(modalType);

  const isPlanning = currentTab?.isPlanning;

  useEffect(() => {
    setNewTabTitles([(!isAdd && tabToUpdate?.title) || '']);
    setSvgIcon((!isAdd && tabToUpdate?.icon) || '');
    setPlanable((!isAdd && tabToUpdate?.planable) || false);
  }, [tabToUpdate, isAdd]);

  const handleTabCreate = async (parentId?: string, isMenu?: boolean) => {
    if (newTabTitles[0]) {
      const resp = await addTab({
        title: newTabTitles[0],
        icon: svgIcon,
        parent_id: isPlanning && planningTab ? planningTab._id : parentId || '',
        isMenu,
        planable,
        isPlanning,
      });

      if (resp) {
        setCount(1);
        setNewTabTitles([]);
        setSvgIcon('');
        getTabs();
        onClose();
      }
    }
  };

  const handleTabEdit = async () => {
    const resp = await updateTab({
      id: tabToUpdate?._id,
      title: newTabTitles[0],
      icon: svgIcon,
      planable,
    });

    if (resp) {
      getTabs();
      onClose();
    }
  };

  const handleTabDelete = async () => {
    if (currentTab) {
      const resp = await deleteTab(tabToUpdate?._id || currentTab?._id);

      if (resp) {
        getTabs();
        onClose();
      }
    }
  };

  const handleTabDuplication = async () => {
    if (currentTab) {
      const resp = await duplicateTab({
        isClone,
        titles: newTabTitles,
        id: tabToUpdate?._id,
      });

      if (resp) {
        getTabs();
        onClose();
      }
    }
  };

  const renderManageTabs = (
    <>
      {modalType === 'delTab' ? (
        <Text>Confirmer la suppressionn de {tabToUpdate?.title} ?</Text>
      ) : (
        <>
          {/duplicate/.test(modalType) && !tabToUpdate?.menuChilds?.length && (
            <InputGroup alignItems='center' mb={3}>
              <FormLabel m={0} mr={3}>
                Cloner
              </FormLabel>
              <Checkbox
                isChecked={isClone}
                onChange={(e) => setIsClone(e.target.checked)}
              />
            </InputGroup>
          )}
          {Array(count)
            .fill('')
            .map((a, idx) => (
              <>
                <VStack spacing='4' marginBottom='4'>
                  <Input
                    type='text'
                    placeholder='Title'
                    value={newTabTitles[idx]}
                    onChange={(e) =>
                      setNewTabTitles((t) => {
                        const ts = [...t];
                        ts[idx] = e.target.value;
                        return ts;
                      })
                    }
                    required
                  />
                  {!/duplicate/.test(modalType) &&
                    (!tabToUpdate?.parent_id || tabToUpdate?.isMenu) && (
                      <InputGroup alignItems='center'>
                        <FormLabel m={0} mr={3}>
                          Planifiable
                        </FormLabel>
                        <Checkbox
                          isChecked={planable}
                          onChange={(e) => setPlanable(e.target.checked)}
                        />
                      </InputGroup>
                    )}
                  {!/duplicate/.test(modalType) && (
                    <Accordion allowMultiple width='100%'>
                      <AccordionItem>
                        <AccordionButton>
                          <Icon
                            alignItems='center'
                            m={2}
                            color={'green'}
                            as={allIcons[svgIcon]}
                            boxSize='30px'
                          />
                        </AccordionButton>
                        <AccordionPanel>
                          {/duplicate|add|edit/.test(modalType) && (
                            <Flex flexWrap='wrap'>
                              {entries(pageIcons).map(([key, icon]) =>
                                svgIcon === key ? null : (
                                  <Icon
                                    alignItems='center'
                                    m={2}
                                    color={'black'}
                                    key={key}
                                    as={icon}
                                    boxSize={8}
                                    onClick={() => setSvgIcon(key)}
                                  />
                                ),
                              )}
                            </Flex>
                          )}
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  )}
                </VStack>
                <Divider my={3} />
              </>
            ))}
          {/duplicate/.test(modalType) && (
            <Button onClick={() => setCount((c) => c + 1)}>+1</Button>
          )}
        </>
      )}
    </>
  );

  return {
    addTab: {
      component: renderManageTabs,
      action: () => handleTabCreate(),
      title: 'Ajouter une page',
      actionLabel: 'Ajouter',
    },
    addMenuSubTab: {
      component: renderManageTabs,
      action: () => handleTabCreate(currentTab?._id, true),
      title: 'Ajouter une sous page',
      actionLabel: 'Ajouter',
    },
    addSubTab: {
      component: renderManageTabs,
      action: () => handleTabCreate(currentTab?._id),
      title: 'Ajouter un onglet',
      actionLabel: 'Ajouter',
    },
    editTab: {
      component: renderManageTabs,
      action: handleTabEdit,
      title: 'Editer une page',
      actionLabel: 'Sauvegerder',
    },
    editMenuSubTab: {
      component: renderManageTabs,
      action: handleTabEdit,
      title: 'Editer une sous page',
      actionLabel: 'Sauvegerder',
    },
    editSubTab: {
      component: renderManageTabs,
      action: handleTabEdit,
      title: 'Editer un onglet',
      actionLabel: 'Sauvegerder',
    },
    delTab: {
      component: renderManageTabs,
      action: handleTabDelete,
      title: 'Supprimer',
      actionLabel: 'Confirmer',
    },
    duplicateTab: {
      component: renderManageTabs,
      action: handleTabDuplication,
      title: `Dupliquer la page: ${tabToUpdate?.title}`,
      actionLabel: 'Dupliquer',
    },
  };
};

export default useManageTabs;
