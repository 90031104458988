import { useSelector } from 'react-redux';
import { Box, Flex } from '@chakra-ui/react';
import ModalTable from '../modal/ModalTable';
import DynamicTable from '../table/DynamicTable';
import SettingsTabs from '../tabs/SettingsTabs';
import SubTabsWithOrder from '../tabs/SubTabsWithOrder';
import Toolbar from './Toolbar';
import Calendar from '../calendar/Calendar';
import { theme } from '../../theme';
import { TState } from '../../redux/store';

const Main = () => {
  const currentTab = useSelector((state: TState) => state.currentTab);
  const isEditMode = useSelector((state: TState) => state.isEditMode);

  const customTab =
    currentTab?.isSetting || currentTab?.isPlanning || currentTab?.isDuplicate;

  return (
    <Flex
      flexDirection='column'
      flexGrow={1}
      w={0}
      px={theme.margin}
      my={['16px', '24px']}
      overflow='hidden'
    >
      <Toolbar />
      <Box flexGrow={1} overflow='hidden'>
        <Flex
          position='relative'
          zIndex={0}
          overflow='hidden'
          id='mainContainer'
          h='100%'
          flexDirection='column'
        >
          {customTab ? null : isEditMode ? (
            <SubTabsWithOrder />
          ) : (
            <DynamicTable />
          )}
          {!isEditMode && currentTab?.isSetting && <SettingsTabs />}
          {currentTab?.isPlanning &&
            (isEditMode ? <SubTabsWithOrder /> : <Calendar />)}
          {currentTab?.isDuplicate &&
            (isEditMode ? <SubTabsWithOrder /> : <DynamicTable />)}
        </Flex>
      </Box>
      <ModalTable />
    </Flex>
  );
};

export default Main;
