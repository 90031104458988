import { useState } from 'react';
import moment, { Moment } from 'moment';
import { Flex, Tag } from '@chakra-ui/react';
import { updateAppointment } from '../../api/planningApi';
import { colors } from '../../theme';
import DropElement from '../dragAndDrop/DropElement';
import { useActions } from '../../redux/store';

const DropMinutes = ({ day, idx }: { day: Moment; idx: number }) => {
  const [dragHoverO, setDragHoverO] = useState(false);
  const [dragHover, setDragHover] = useState('');

  const { fetchAppointments } = useActions();

  return (
    <Flex
      w='100%'
      opacity={dragHoverO ? 1 : 0}
      onDragOver={() => setDragHoverO(true)}
      onDragLeave={() => setDragHoverO(false)}
    >
      {['00', '15', '30', '45'].map((min) => {
        const dateTime = `${moment(day).format('YYYY-MM-DD')} ${
          8 + idx
        }:${min}:00`;

        return (
          <DropElement
            noDrag={true}
            id={dateTime}
            onDrop={async (id: string, hour: string) => {
              setDragHoverO(false);
              setDragHover('');

              await updateAppointment(id, {
                planningDate: hour,
              });

              fetchAppointments();
            }}
            type='CALENDAR'
          >
            <Tag
              onDragOver={() => setDragHover(min)}
              onDragLeave={() => setDragHover('')}
              display='flex'
              flexGrow={1}
              mx={1}
              backgroundColor={
                dragHover === min ? `${colors.main}.300` : `${colors.main}.100`
              }
            >
              {min}
            </Tag>
          </DropElement>
        );
      })}
    </Flex>
  );
};

export default DropMinutes;
