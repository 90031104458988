import { useState, useRef, useEffect, FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import DropElement from './DropElement';
import { isEmpty } from '../../helpers';
import { isEqual } from 'lodash';

const DragDropList = <T,>({
  listItem,
  List,
  type,
  noDragIndexes,
  noDrag,
  onDrag,
  onDrop,
}: {
  listItem: T[];
  List: FC<{ item: T; idx: number }>;
  type: string;
  noDragIndexes?: number[];
  noDrag?: boolean;
  onDrag?: (index: number) => void;
  onDrop: (list: T[]) => void;
}) => {
  const [orderedList, setOrderedList] = useState(listItem);
  const currIndex = useRef<any>();

  const tempList = useRef<T[]>([]);

  useEffect(() => {
    setOrderedList(listItem);
  }, [listItem]);

  const onMove = (dragIndex: number, dropIndex: number) => {
    currIndex.current = dragIndex;

    if (isEmpty(dropIndex) || isEmpty(dragIndex)) return;

    const currList = [...tempList.current];
    const [item] = currList.splice(dragIndex, 1);
    currList.splice(dropIndex, 0, item);

    setOrderedList(currList);
  };

  const onCancel = () => {
    if (!isEqual(tempList.current, orderedList)) {
      // @TODO cancel action
    }
  };

  const onDragDefault = () => {
    tempList.current = orderedList;
    onDrag?.(currIndex.current);
  };

  const onDropDefault = () => {
    if (!isEqual(tempList.current, orderedList)) {
      onDrop(orderedList);
    }
  };

  return (
    <Flex flexDirection='column' w='100%'>
      {orderedList.map((item, idx) => (
        <DropElement
          noDrag={noDragIndexes?.includes(idx) || noDrag}
          id={`${idx}`}
          type={type}
          onMove={onMove}
          onDrag={onDragDefault}
          onDrop={onDropDefault}
          onCancel={onCancel}
        >
          <List item={item} idx={idx} />
        </DropElement>
      ))}
    </Flex>
  );
};

export default DragDropList;
